.api-method{
    color:white;
    font-size: 0.6rem;
    border-radius: 10px;
    padding: 1px 6px;
    text-align: center;
    margin-left: 5px !important;
}

.api-method-post{
    background-color: rgb(65, 65, 246);
}

.api-method-get{
    background-color: rgb(76, 160, 76);
}

.api-method-delete{
    background-color: rgb(255, 75, 75);
}

.api-endpoint{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.85rem;
    cursor: pointer;
    padding:4px 10px;
}

.api-endpoint:hover{
    background-color: var(--primary-color);
    color:white;
    
}

.api-section{
    border-right: 1px solid lightgray;
}
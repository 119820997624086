.parameters-container>p{
    color: gray;
    font-size: 0.9rem;
}

.parameters-container>select{
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    outline: none;
    padding: 5px;
    background-color: transparent;
    font-size: 0.9rem;
}


.send-param-btn{
    border: 1px solid var(--primary-color);
    padding: 6px 12px;
    cursor: pointer;
    color: var(--primary-color);
    border-radius: 8px;
    transition: all 0.4s ease;
    background-color: transparent;
    /* width: 150px; */
}

.send-param-btn:hover{
    background-color: var(--primary-color);
    color: white;
}
.annual-button{
    color:var(--primary-color) ;
    background-color: white ;
    border-radius: 0.8rem;
    border: 1px solid var(--primary-color);
    transition: all 0.25s ease-in;
}

.annual-button:focus{
    color:white;
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.annual-button:hover{
    color:white;
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}
.login-form{
    border-radius: 1.25rem;
    background: transparent;
    padding: 0 3rem;
}

.login-button{
    border-radius: 2rem !important;
    background: var(--primary-color) !important;
    font-weight: 400;
}

.login-google{
    border:1px solid lightgray;
    border-radius: 2rem;
    background-color: white;
    color:black
}

.login-form>input:focus{
    outline: none;
    /* border: none; */
}

.login-form>input{
    border: 1px solid gray;
    border-radius: 3px;
    padding: 2px 8px;
}

.login-form>input::placeholder{
    font-size: 0.9rem;
}


.login-page{
    height: 90vh;
    width: 100%;
    /* border: 1px solid red; */
    padding: 0 140px !important;
    backdrop-filter: blur(80px);
}

.github-link:hover{
    cursor: pointer;
}

.searchbar-list {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .searchbar-list p {
    margin: 0;
  }
  
  .searchbar-list a:hover {
    background-color: #f1f1f1;
  }
  
.header-links>a{
    margin: 0 14px !important;
}

.header{
    height: 12vh;
    /* background-color: red !important; */
}
.header-heading{
    /* width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; */
    /* border: 1px solid red; */
}

.dashboard-header-heading{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header-onboarding>a{
    margin: 0 10px !important;
}

.header-onboarding>button{
    background: none;
    border: none;
}

.header-signup-btn{
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;
    background-color: transparent;
}

.header-signup-btn:hover{
    background-color: var(--primary-color);
    color: white !important;
}

.text-uppercase>a:hover{
    color:white !important;
}

.footer-web-link:hover{
    color:white !important;
}
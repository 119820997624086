.nav-links>a{
    font-size: 0.875rem;
    margin: 0 10px !important;
    color:white !important;
}

.nav-projects-link{
    border: 2px solid white !important;
    border-radius: 9999px;
    padding: 5px 10px;
}

.nav-projects-link:hover{
    background-color: #78A083;
    color: white !important;
    border: 2px solid #78A083 !important;
}

.nav-account-link{
    font-size: 0.875rem;
    border: 2px solid white !important;
    outline: none;
    border-radius: 9999px;
    padding: 5px 10px;
    background-color: transparent;
    cursor: pointer;
    color: white !important;
}

.nav-account-link:hover{
    background-color: #78A083;
    color: white !important;
    border: 2px solid #78A083 !important;
}
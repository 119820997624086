body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: white ; */
}

:root{
  --primary-color: #15589c;
  --secondary-color: #a1bcd7;
  /* --background-color: white !important; */
  --text-color: #000000;
}

*{
  margin: 0;
  padding: 0;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  margin-block-end: 0 !important;
  margin-block-start: 0 !important;
  font-family: 'Nunito Sans', sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

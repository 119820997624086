.newprojectfirstmenu{
    background-color: white;
    height: 100vh;   
    min-height: 100vh;
    width: 190px;
    border-right: 6px solid #0b2c4e;
}

.newprojectfirstmenu>a{
    font-size: 0.85rem;
    color:black;
    padding: 3px 14px;
}

.newprojectfirstmenu>a:hover{
    background-color: #0f3e6d;
    color:white !important;
}

.newprojectfirstmenu>a:focus{
    background-color: #0f3e6d !important;
    color:white !important;
}

.disabled-component{
    opacity: 0.5;
    pointer-events: none;
}

.disabled-component-table{
    pointer-events: none;
}

.plotted-graph-name>a:hover{
    background-color: var(--primary-color);
    color:white;
    cursor: pointer;
}

.plotted-graph-name>a:focus{
    background-color: var(--primary-color);
    color:white;
    cursor: pointer;
}

.plotted-graph-name>a:focus .index-filename{
    color:white
}

.plotted-graph-name>a:hover .plotted-span{
    background-color: white !important;
}

.plotted-span{
    clip-path: circle();
}

.plotted-graph-name>a:hover .filename-p{
    color: white !important;
}

.index-filename{
    color:var(--primary-color);
    font-weight: bold;
}

.plotted-graph-name>a:hover .index-filename{
    color:white;
}

.plotted-graph-name>a:hover .plotted-graph-name-p{
    color:white !important
}
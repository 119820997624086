.newproject-top-right-links{
    color:white !important;
    font-size: 0.9rem;
}

.newproject-top-right-links>a{
    color:white !important
}

/* Add this CSS to your stylesheet */

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: var(--primary-color);
    color: white;
    padding: 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-item {
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-item:focus{
    background-color: transparent !important;
  }
  
  .dropdown-item button {
    background-color: rgb(222, 58, 58);
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 0.7rem;
  }
  
  .dropdown-item button:hover {
    background-color: darkred;
  }
  